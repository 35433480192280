<template>
  <div class="pdf" style="height: 100%;">
    <iframe
      id="iframe_id"
      ref="iframe"
      width="100%"
      height="100%"
      :src="url + '#view=FitH,top&toolbar=0'"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    pdfUrl: {
      type: String
    }
  },
  data () {
    return {
      fileFormatArr: [ 'doc', 'docx', 'xlsx', 'xls' ]
    };
  },
  computed: {
    url () {
      if (this.pdfUrl !== '') {
        let url;
        const fileFormat = this.pdfUrl.split('.');
        const index = this.fileFormatArr.findIndex((item) => item === fileFormat[fileFormat.length - 1]);
        // const str = '#view=FitH,top&toolbar=0'
        if (index !== -1) {
          url = `https://view.officeapps.live.com/op/view.aspx?src=${this.pdfUrl}`;
        } else {
          url = this.pdfUrl;
        }
        return url;
      } else {
        return 'about:blank';
      }
    }
  },
  methods: {
    clearIframe () {
      const el = document.getElementById('iframe_id');
      const iframe = el.contentWindow;
      if (el) {
        el.src = 'about:blank';
        try {
          iframe.document.write('');
          iframe.document.clear();
        } catch (e) {
          console.log(e);
        }
      }
      el && el.parentNode.removeChild(el);
    }
  },
  beforeDestroy () {
    // this.$emit('delete')
  }
};
</script>
