<template>
  <div style="position: relative;">
    <!-- <vipPermission v-if="showVipCom" :vipPermissionVisible="true"></vipPermission> -->
    <div v-if="huopimingdan === ''" class="mingdan-details">暂无获批名单</div>
    <div v-else class="mingdan-details">
      <proviewPdf :pdf-url="huopimingdan" v-if="hpmdShowPdf" ref="pdfView"></proviewPdf>
      <img :src="huopimingdan" alt="" v-else class="set-width" style="width:100%;height:100vh;margin-bottom:100px">
    </div>
  </div>
</template>

<script>
import proviewPdf from './components/proviewPdf';
import { mapState } from 'vuex';
export default {
  components: {
    proviewPdf
  },
  data () {
    return {
    };
  },
  computed: {
    // ...mapState('policy', [ 'huopimingdan', 'hpmdShowPdf' ])
    ...mapState({
      huopimingdan: state => state.policy.huopimingdan,
      hpmdShowPdf: state => state.policy.hpmdShowPdf,
      isVip: (state) => state.login.isVip
    }),
    showVipCom () {
      return this.isVip === '普通用户';
    }
  },
  methods: {
  }
};
</script>

<style lang="less" scoped>
.mingdan-details {
  height: 100vh;
  border: 10px solid #F5F5F5;
}
</style>
